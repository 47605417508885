<template>

  <div class="mt-3 mx-3">
    <div class="row text-center" align="center">
      <div class="col-sm-12 col-12 d-flex justify-content-center">
        <div v-if="event_item.channels.length != 0 && event_item.channels[0].status && event_item.channels[0].active" class="card-status-live">
          <p class="mb-0">LIVE</p>
        </div>
        <div v-else class="card-status-offline">
          <p class="mb-0">OFFLINE</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mx-2">
    <div class="col-sm-12 col-12" align="center">
      <div class="card-team-name">{{event_item.firstMatcher}}</div>
      <div class="card-vs">VS</div>
      <div class="card-team-name">{{event_item.secondMatcher}}</div>
    </div>
  </div>
  <div class="row mx-2">
    <div class="col-sm-12 col-12">
      <div class="match-type" align="center">
        <span class="fw-bold">{{event_item.league}}</span>
      </div>
    </div>
  </div>

  <!-- play button  -->
  <div class="row mx-2 mb-3">
    <div class="col-sm-12 col-12 mt-2" align="center">
      <button v-if="event_item.channels.length != 0 && event_item.channels[0].status && event_item.channels[0].active" class="btn text-white card-play-btn rounded-5 text-center" @click="toggleIsClicked">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-play-fill" viewBox="2 2 16 16">
          <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
        </svg>
        PLAY
      </button>
      <button v-else class="btn text-white card-play-btn rounded-5 text-center" @click="toggleIsClicked" disabled>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-play-fill" viewBox="2 2 16 16">
          <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
        </svg>
        PLAY
      </button>
    </div>
  </div>

</template>

<script>
import {defineComponent} from "vue";
import VueSimpleAlert from 'vue3-simple-alert';

export default defineComponent({
  name: "EventItem",
  props: ['event_item', 'dynamicData'],
  data() {
    return {
      userProfile: null,
    }
  },
  methods: {
    toggleIsClicked: function () {
      if (!this.event_item.channels[0].status || !this.event_item.channels[0].active || this.dynamicData.edgeAddress === "" || this.dynamicData.edgeAddress === undefined) {
        VueSimpleAlert.alert("Channel is not available at the moment", "Channel error")
      } else {
        localStorage.setItem('eventTitle', this.event_item.firstMatcher + " VS " + this.event_item.secondMatcher)
        localStorage.setItem('channel', JSON.stringify(this.event_item.channels[0]))
        if (this.event_item.channels[1] && this.event_item.channels[1].status && this.event_item.channels[1].active)  {
          localStorage.setItem('backupChannel', JSON.stringify(this.event_item.channels[1]))
        }
        this.$router.push('/player')
      }
    },
  },
  beforeMount() {
    if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
  }
})
</script>

<style>
@import '../assets/style.css';
</style>
